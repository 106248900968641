.landing-bg {
  background-image: url(./img/Hero-Image.jpg);
  background-attachment: fixed;
  height: 100vh;
  overflow: hidden;
}

.landing-container {
  background-color: white;
  border-radius: 25px;
}

.landing-vertical-center {
  width: 600px;
  height: 410px;
  padding: 30px;
}

.landing-button {
  width: 150px;
}

.landing-content {
  background: transparent;
}
