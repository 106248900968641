@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';

// Sub Page styles
@import './pages/LandingPage/LandingPage.scss';

// General Settings
.umt--breadcrumb {
  padding-top: $spacing-05;
  padding-bottom: $spacing-05;
}
.umt--heading {
  font-size: carbon--type-scale(5);
  padding-bottom: $spacing-05;
}

// Styles for Tile Statistics
.umt--tile-header {
  font-size: carbon--type-scale(1);
}
.umt--tile-value {
  font-size: carbon--type-scale(5);
}

// Styles for Tile Descriptions
.umt--tile-title {
  padding-top: $spacing-03;
  font-size: carbon--type-scale(3);
}
.umt--tile-description {
  font-size: carbon--type-scale(1);
}

// Styles of tables
.umt--table-container {
  padding-top: $spacing-05;
}

.umt--table-overflow {
  text-align-last: right;
  width: $spacing-07;
}

.umt--table-overflow-button {
  padding: 0px;
}

.umt--row-cta {
  padding-top: $spacing-05;
}

.umt--col-bleed {
  display: flex;
  justify-content: flex-end;
}

// Styles for side-tiles (helper tiles)
.umt--helper-tile {
  border-top: 1px;
  border-top-color: blue;
}

.umt--helper-heading {
  font-size: carbon--type-scale(3);
  padding-bottom: $spacing-05;
}

.umt--helper-p {
  font-size: carbon--type-scale(1);
  padding-bottom: $spacing-10;
}